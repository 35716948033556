import combine from "./combine";
import connectors from "./connectors";
import connectorUpdate from "./connectorUpdate";
import dateRangePicker from "./dateRangePicker";
import updateMember from "./updateMember";
import teamMembers from "./teamMembers";
import ibanForm from "./ibanForm";
import filterDropdown from "./filterDropdown";
import connectionModal from "./connectionModals";
import invoiceUploader from "./invoiceUploader";
import fileUploader from "./fileUploader";
import payoutInvoiceArchiveModalData from "./invoiceTable";
import mailValidationForm from "./mailValidationForm";
import countrySelect from "./countrySelect";
import companySearch from "./companySearch";
import companySelect from "./companySelect";
import formValidity from "./formValidity";
import commonSelect from "./commonSelect";
import avatarCrop from "./avatarCrop";
import dropdown from "./dropdown";
import bankSelector from "./bankSelector";
import bankRedirect from "./bankRedirect";
import formCompany from "./formCompany";
import formOffer from "./formOffer";
import webcallback from "./webcallback";
import datePicker from "./datePicker";
import searchSelect from "./searchSelect";
export default [
  combine,
  connectors,
  connectorUpdate,
  updateMember,
  dateRangePicker,
  teamMembers,
  ibanForm,
  filterDropdown,
  connectionModal,
  invoiceUploader,
  fileUploader,
  payoutInvoiceArchiveModalData,
  mailValidationForm,
  countrySelect,
  companySearch,
  companySelect,
  formValidity,
  commonSelect,
  avatarCrop,
  dropdown,
  bankSelector,
  bankRedirect,
  formCompany,
  formOffer,
  webcallback,
  datePicker,
  searchSelect,
];
